<template>
    <div class="yj-conten" style="min-height: calc(100% - 48px);min-width:max-content;width:calc(100% - 32px); background-color:#fff;">
        <a-layout-content style="height:100%;">
            <a-layout style="height:100%;background:#fff;padding:20px">
                <a-layout-header style="height:initial;background:#fff;padding: 0;">
                    <a-form layout="horizontal" style="height:auto;">
                        <a-form-item label="" style="margin-bottom:10px;"
                                     :label-col="formItemLayout.labelCol"
                                     :wrapper-col="formItemLayout.wrapperCol">
                            <a-form layout='inline'>
                                <a-form-item>
                                    <label class="yj-partner-check_lab">业务单号：</label>
                                    <a-input placeholder="业务单号" style="width:260px;" @change="name_change" />
                                </a-form-item>
                                <a-form-item label="">
                                    <a-button type="primary" class="margin-r" @click="search">
                                        查询
                                    </a-button>
                                    <a-button type="primary" class="margin-r" ghost @click="exportdata">
                                        导出
                                    </a-button>
                                </a-form-item>
                                <a-form-item>
                                    <a-button @click="exportsettlement" style="margin-left:10px;">
                                        导出线下结算
                                    </a-button>
                                </a-form-item>
                            </a-form>
                        </a-form-item>
                    </a-form>
                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns"
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="false"
                                 @change="pagerChange"
                                 class="yj-table-img" style=" min-height:calc(100vh - 246px)"
                                 :row-selection="rowSelection">
                            <span slot="name" slot-scope="text, record">
                                <a @click="PartnerDetail(record)">{{record.BusinessID }}</a>
                            </span>
                            <span slot="SettleStatus" slot-scope="text, record">{{ ShowEnum2(record.SettleStatus) }}</span>
                            <span slot="SettleMode" slot-scope="text, record">{{record.SettleStatus==1?ShowEnum3(record.SettleMode):'' }}</span>
                        </a-table>
                    </div>
                    <div style="padding:10px 0 0">
                        <a-row type="flex" justify="space-between">
                            <a-col :span="3">
                                <a-button type="primary" class="margin-r" ghost @click="batchgrant">
                                    批量结算
                                </a-button>
                                <a-modal title="批量结算"
                                         :visible="visible"
                                         @ok="handleOk"
                                         @cancel="handleCancel"
                                         okText="确认"
                                         cancelText="取消">
                                    <p>
                                        <a-radio-group v-model="SettleMode">
                                            <a-radio :value="1">
                                                线下
                                            </a-radio>
                                            <a-radio :value="0">
                                                线上
                                            </a-radio>
                                        </a-radio-group>
                                        <a-input placeholder="备注~" v-model="Remark" type="textarea" style="margin-top:20px;" />
                                    </p>
                                </a-modal>
                            </a-col>
                            <a-col :span="12" class="textal_r">
                                <a-pagination :total="pagination.total"
                                              show-quick-jumper
                                              v-model="pagination.current"
                                              @change="pagerChange"></a-pagination>
                            </a-col>
                        </a-row>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                visible: false,
                Remark: '',
                SettleMode: 1,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                columns: [
                    {
                        title: "业务单号",
                        width: 200,
                        scopedSlots: { customRender: 'name' }
                    },
                    {
                        title: "摘要",
                        align: "left",
                        width: 200,
                        dataIndex: "Abstract"
                    },
                    {
                        title: "金额",
                        align: "right",
                        width: 200,
                        customRender: (text, item) => {
                            return util.ToFixed(item.SettleMoney);
                        }
                    },
                    {
                        title: "结算状态",
                        width: 200,
                        align: "center",
                        scopedSlots: { customRender: 'SettleStatus' }
                    },
                    {
                        title: "结算方式",
                        width: 200,
                        align: "center",
                        scopedSlots: { customRender: 'SettleMode' }
                    },
                    {
                        title: "日期",
                        align: "left",
                        width: 320,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    }
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                name: "",
                selectedRowKeys: [],
                id: 0,
                jsondata2: [],
                jsondata3: []
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            handleOk() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerSettlement/Settle",
                    data: {
                        SettleOrderID: self.selectedRowKeys,
                        SettleMode: self.SettleMode,
                        Remark: self.Remark,
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        self.visible = false;
                        self.pagination.current = 1;
                        self.getTableData();
                    }
                };
                http.Post(op);
            },
            handleCancel() {
                var self = this;
                self.visible = false;
            },
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
                console.log("self.name:",self.name);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerAccounts/GetPartnerAccountsDetail",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.name,
                        UserID: self.id,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            exportdata: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerAccounts/GetPartnerAccountsDetailExcel",
                    data: {
                        name: self.name,
                        UserID: self.id
                    },//下载接口参数
                    FileName: '合伙人订单.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
            exportsettlement: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerAccounts/GetPartnerAccountsDetailExcel",
                    data: {
                        name: self.name,
                        SettleStatus: 1,
                        SettleMode: 1,
                        UserID: self.id
                    },//下载接口参数
                    FileName: '合伙人订单.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
            PartnerDetail(e) {
                this.$router.push({
                    name: "Order_OrderDetail",
                    query: { ID: e.BusinessID }
                });
            },
            batchgrant: function () {
                var self = this;
                if (self.selectedRowKeys == '') {
                    this.$info({
                        title: '提示',
                        content: '请选择订单！',
                        okText: "确定"
                    });
                } else {
                    self.visible = true;
                }
            },
            getData3: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.PartnerModule",//程序集名称
                        TypeName: "Welcome.PartnerModule.Enumerate.SettlementStatusEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata2 = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum2: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata2.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            },
            getData4: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.PartnerModule",//程序集名称
                        TypeName: "Welcome.PartnerModule.Enumerate.SettlementModeEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata3 = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum3: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata3.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            }
        },
        computed: {//计算属性
            rowSelection() {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        var self = this;
                        self.selectedRowKeys = selectedRowKeys;
                        console.log(selectedRows);
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name,
                        },
                    }),
                };
            }
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.id = this.$route.query.id;
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getData4();
            this.getData3();
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>